<template>
  <button
    v-if="!store.getters.hasOnderaannemerPermission"
    :disabled="disabled"
    class="text-xs"
    @click="handleClick"
  >
    <span v-if="status > 0" class="btn bg-green-400 text-white text-xs font-bold rounded-sm px-2">
      <i class="fas fa-link"></i> Combinatie <span v-if="store.getters.hasKantoorPermission">#{{ status }}</span>
    </span>
    <span v-else class="btn bg-gray-200 text-gray-700 text-xs font-bold rounded-sm px-2">
      <i class="fas fa-link"></i>
    </span>
  </button>
</template>

<script setup>
import { defineProps } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  disabled: Boolean,
  status: [Number, String],
})
const emit = defineEmits(['update:status'])
const store = useStore()
const handleClick = () => {
  if (props.disabled) return
  const newVal = Number(props.status) > 0 ? 0 : 1
  emit('update:status', newVal)
}
</script>
